<template>
    <div>
        <div class="info_warp" v-if="loading">
            <div class="info_nav" @click="getBack">
                <van-icon name="arrow-left" />
                <p v-html="info.conference_name"></p>
            </div>
            <div class="info_content">
                <template v-for="(item, index) in this.info.content_url">
                    <div :key="index">
                        <div class="info_img_warp" v-if="item.type == 1">
                            <div class="info_content_img" @click="open_view(item.url)">
                                <img :src="item.url" alt="">
                            </div>
                            <div class="info_link" @click="go_link(item.station_url)" v-if="item.station_url">
                                <div class="underline">会议链接入口</div>
                                <img src="@/assets/icon/links-fill.svg" alt="">
                            </div>
                        </div>
                        <div class="info_video" v-else-if="item.type == 2">
                            <div class="video_warp">
                                <video class="video" :id="`video${index}`" :ref="`video${index}`"
                                    :poster="item.cover_picture_url" :controls="item.controls" :src="item.url">
                                </video>
                                <div class="video_within" v-show="!item.controls">
                                    <div class="video_time">
                                        {{ item.duration }}
                                    </div>
                                    <img src="@/assets/img/play.svg" alt="" class="video_play"
                                        @click="play_event(index, item)">
                                </div>
                            </div>
                            <div class="info_link" @click="go_link(item.station_url)" v-if="item.station_url">
                                <div class="underline">会议链接入口</div>
                                <img src="@/assets/icon/links-fill.svg" alt="">
                            </div>
                        </div>
                    </div>

                </template>

            </div>
            <van-image-preview v-if="imgShow" v-model="imgShow" :images="imgSrc" :show-index="false" :closeable="true"
                @closed="handleClose">
            </van-image-preview>
        </div>
        <van-loading :color="themecolors" :text-color="themecolors" class="loading_s" v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            videoList: [],
            loading: false,
            info: {},
            imgShow: false,
            imgSrc: [],
        }
    },
    computed: {
        ...mapState(['themecolors']),
    },
    created() {
        this.get_front_calendar_conference_info()
    },
    methods: {
        getBack() {
            this.$router.go(-1)
        },
        play_event(index, data) {
            this.$refs[`video${index}`][0].play()
            data.controls = true
            this.$forceUpdate()
        },
        get_front_calendar_conference_info() {
            this.$store.dispatch('get_front_calendar_conference_info', {
                id: this.$route.query.id
            }).then(res => {
                if (res.data.code == 200) {
                    this.info = res.data.data
                    let reg=new RegExp('<br>|<br/>','g')
                    this.info.conference_name =  this.info.conference_name.replace(reg,'')

                    this.info.content_url.forEach(element => {
                        if (element.type == 2) {
                            element.controls = false
                        }
                    });
                }
            }).finally(() => {
                this.loading = true
            })
        },
        go_link(url) {
            window.open(url)
        },
        // 预览图片
        open_view(url) {
            this.imgShow = true
            this.imgSrc[0] = url
        },
        handleClose() {
            this.imgSrc = [];
            this.imgShow = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.loading_s {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info_warp {
    padding: 0 .16rem .1rem;
    overflow-y: auto;
    height: 100%;
    background: #fff;

    .info_nav {
        position: sticky;
        top: 0;
        padding: .12rem 0;
        color: #1D2129;
        font-size: .14rem;
        font-weight: bold;
        background: #fff;
        display: flex;
        align-items: center;

        .van-icon {
            font-weight: bold;
            font-size: .18rem;
            margin-right: 0.08rem;

        }

        p {
            width: calc(100% - .50rem);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .info_content {
        margin-top: .16rem;
        font-size: .12rem;

        .info_content_img {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .info_link {
            padding: 0.12rem 0 .1rem;
            margin-bottom: .2rem;
            border-bottom: .01rem solid #F2F6F8;
            display: flex;
            justify-content: space-between;
            color: #00CCB0;
            cursor: pointer;

            .underline {
                text-decoration: underline
            }
        }

        .info_video {
            margin-top: .2rem;

            .video_warp {
                width: 100%;
                height: 1.92rem;
                border-radius: .08rem;
                position: relative;
                overflow: hidden;

                .video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }

                &:last-child {
                    margin-bottom: 0;
                }

                .video_within {
                    position: absolute;
                    bottom: 0;
                    height: .44rem;
                    line-height: .44rem;
                    padding: 0 .12rem;
                    width: 100%;
                    color: #fff;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

        }
    }

}
</style>